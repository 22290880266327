import '../../stylesheets/views/home'

import Glider from 'glider-js/glider'
import 'glider-js/glider.css'

onLoad(() => {

  const glider = new Glider(document.querySelector('#slider'), {
    slidesToShow: 1,
    dots: '#slider-dots',
    arrows: {
      prev: '.glider-prev',
      next: '.glider-next'
    }
  })

})
